import React from "react"
import Layout from "../components/layout"
import HomeContent from "../components/home-content"

const IndexPage = props => {
  return (
    <Layout>
      <HomeContent photo={props.data.bioPhoto} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    bioPhoto: file(relativePath: { eq: "bio-photo.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
