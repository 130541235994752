import styled from "styled-components"
import { device } from "./device"
import { motion } from "framer-motion"

export const Content = styled(motion.div)`
  display: grid;
  width: 320px;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1rem;
  row-gap: 1rem;

  @media ${device.tablet} {
    grid-template-columns: 1fr 3.5fr 2fr 1fr;
    grid-template-rows: auto;
    column-gap: 2rem;
    row-gap: 3rem;
    width: 100%;
    margin-bottom: 3rem;
  }
`
export const BioImage = styled(motion.div)`
  visibility: hidden;

  .gatsby-image-wrapper {
    max-width: 27rem;
    max-height: 33rem;
  }

  @media ${device.tablet} {
    visibility: visible;
    position: relative;
    grid-column: 3/4;
    grid-row: 2/3;
    width: 25rem;
    height: 30rem;
  }
`

export const Last = styled(motion.span)``

export const First = styled(motion.span)``

export const Tagline = styled(motion.p)`
  font-size: 1rem;
`

export const Blurb = styled(motion.div)`
  grid-column: 1 / span 4;
  grid-row: 1 / span 2;
  color: ${props => props.theme.black};
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;

  .name {
    font-family: "Playfair Display", serif;
    font-weight: 400;
    font-size: 4rem;
    line-height: 1;
    text-transform: uppercase;
  }

  ${Last} {
    align-self: flex-end;
  }
  @media ${device.tablet} {
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;
    justify-content: center;
    color: ${props => props.theme.black};
    padding: 0;

    .name {
      font-size: 6vw;
      font-weight: 400;
    }

    //TOOD: fix
    ${Last} {
      align-self: center;
    }

    ${Tagline} {
      font-size: 1.5rem;
      font-weight: 200;
    }
  }
`
