import React from "react"
import { Container, customEase, easeInOut } from "../styles/global-styles"
import Img from "gatsby-image"
import {
  Content,
  First,
  Last,
  Tagline,
  Blurb,
  BioImage,
} from "../styles/home-content-styles"
import {
  useGlobalStateContext,
  useGlobalDispatchContext,
} from "../context/globalContext"

const HomeContent = ({ photo }) => {
  const { firstLoad } = useGlobalStateContext() || true
  const dispatch = useGlobalDispatchContext()
  const updateFirstLoad = () => dispatch({ type: "FIRST_LOAD", payload: false })

  const container = {
    initial: firstLoad ? { y: 0, opacity: 1 } : false,
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        // staggerChildren: 0.1,
      },
    },
  }
  const item = {
    initial: firstLoad ? { y: 100, opacity: 0 } : false,
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1,
        ease: customEase,
      },
    },
  }
  return (
    <Container
      initial={firstLoad ? { opacity: 1 } : { opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={easeInOut(0.6)}
    >
      <Content>
        <Blurb
          variants={container}
          initial="initial"
          animate="animate"
          onAnimationComplete={updateFirstLoad}
        >
          <First className="name" variants={item}>
            Alvin
          </First>
          <Last className="name" variants={item}>
            Ghouas
          </Last>
          <Tagline variants={item}>
            Software Engineer, passionate about Human Interfaces
          </Tagline>
        </Blurb>
        <BioImage
          animate={{ y: 0, opacity: 1 }}
          initial={firstLoad ? { y: -72, opacity: 0 } : false}
          transition={{ duration: 1, ease: customEase }}
        >
          <Img fluid={photo.childImageSharp.fluid} />
        </BioImage>
      </Content>
    </Container>
  )
}

export default HomeContent
